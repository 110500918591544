<!-- 选择议价商品 -->
<template>
	<div :class="themeClass">
		<el-dialog v-dialogDrag v-if="showModel" width="60%" :visible.sync="showModel"
			:header-cell-style="{'text-align': 'center',fontWeight: '400',color: '#333',}" :before-close="handleClose"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<div>
				<div style="z-index: 999;color:#1e1e1e;padding-left: 20px;font-size: 18px;">您还未开通票据钱包，是否去开通?</div>
				<!-- 开通票据 -->
				<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
					<div class="form-Box">
						<div class="right-box padding-lr-20 bg-fff">
							<div class="richtext-box margin-b-10">
								<div v-if="content != null" v-html="content"></div>
							</div>
						</div>
					</div>
					<agreementDialog :iContents='iContent' ref="areDialog"></agreementDialog>
				</div>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
				<el-button type="primary"  class="dia-btn text-center"style="cursor: pointer;"
					@click="nextStepClick">立即开通</el-button>
				<el-button class="dia-btn text-center" @click="handleClose">暂不开通</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import agreementDialog from '@/components/agreementDialog';
	import {
		mapGetters
	} from "vuex";
	export default {
		props: {
			showModel: {
				type: Boolean,
				default: false,
			},
		},
		components: {
			agreementDialog,
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		data() {
			return {
				fTitle: '', //富文本标题
				content: '', //富文本内容
				rule: [], //富文本规则
				countDown: 10,
				validStatue: false,
				subStatue: false,
				BranchSelection: false,
				zftAgreement: [],
				dialogFormVisible: false,
				formLabelWidth: "80px",
				dialogruleForm: {
					money: null,
				},
				loading: true,
				title: '',
				type: '',
				fAccount: '',
				changeAccount: false,
				userinfo: {},
				bankList: [], //开户行
				certificateType: [],
				verificationCodeText: "获取验证码",
				ss: "",
				fLegalPerson: '',
				fBankCode: '',
				agreementData: [], //店铺协议
				ruleForm: {
					radio: false,
				},
				rules: {
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意开通票据钱包协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				},
				obj: null,
				noticeShow: false,
				iContent: '',
				fkhmC1List: [],
				fkhmC1ListCopy: [],
				fBranchID: '',
				promiseTimer: '',
				fAgreementHistID : '',
				noticeShow: false,
			};
		},
		mounted() {
			this.getInfo()
			this.getAgreementHistory()
		},
		methods: {
			submitForm() {
				// 关闭弹窗
				this.handleClose();
			},
			// 弹窗关闭事件
			handleClose() {
				this.$emit("change", !this.showModel);
			},
			//   获取用户信息
			getInfo() {
				this.ApiRequestPost(
					"/api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full", {}
				).then(
					(result) => {
						console.log(result, "00000000000");
						this.userinfo = result.obj
					},
					(rej) => {
					}
				);
			},
			showAgreeDia(a) {
				this.noticeShow = true;
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			//获取协议内容
			getAgreementHistory() {
				this.ApiRequestPost('api/mall/ebbase/agreement-history/get-list-by-b2c-ebcn', {
					fShopID: 5,
				})
					.then(
						res => {
							this.loading = false
							console.log('获取协议id', res);
							res.obj.items.forEach((item, index) => {
								if (index == 0) {
									res.obj.items[index].checked = true;
									this.fTitle = res.obj.items[index].fTitle;
									this.fAgreementHistID = res.obj.items[index].fAgreementHistID;
								} else {
									res.obj.items[index].checked = false;
								}
							});
							this.zftAgreement = res.obj.items
							this.agreementData = res.obj.items;
							this.getAgreementHistorys();
						},
						error => {}
					);
			},
			//获取协议内容
			getAgreementHistorys() {
				this.ApiRequestPost('api/mall/ebbase/agreement-history/get', {
					FAgreementHistID: this.fAgreementHistID
				})
					.then(
						res => {
							this.content = res.obj.fContent
							this.loading = false
							let _this = this;
							this.promiseTimer = setInterval(function() {
								_this.countDown = _this.showtime()
							}, 1000);
						},
						error => {}
					);
			},
			showtime() {
				let second = 10;
				if (this.countDown > 0) {
					this.countDown -= 1;
				} else if (this.countDown == 0) {
					clearInterval(this.promiseTimer)
				}
				return this.countDown; //返回倒计时的字符串
			},
			//协议同意并继续
			nextStepClick() {
						this.ApiRequestPost(
							"api/mall/ebbalance/customer-acceptance/open-acceptance-wallet", {}
						).then(
							(result) => {
								this.$message(result.message)
								this.$forceUpdate()
								this.handleClose()
							},
							(rej) => {
							}
						);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-table_2_column_8 {
		padding-left: 10px;
	}

	.el-table-column--selection {
		::v-deep .cell {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	::v-deep td:first-child {
		padding-left: 0 !important;
	}

	::v-deep .gutter {
		display: none;
	}

	::v-deep .el-table--border {
		padding-left: 0 !important;
	}

	::v-deep .el-table--border td:first-child .cell {
		padding-left: 0 !important;
	}

	::v-deep .el-table-column--selection .cell {
		padding-left: 0;
		padding-right: 0;
	}

	::v-deep .el-table .cell.el-tooltip {
		min-width: 30px;
	}

	::v-deep td:first-child {
		padding-left: 0 !important;
	}

	::v-deep .el-table--border th:first-child .cell {
		padding-left: 0;
		padding-right: 0;
	}

	::v-deep .el-table th>.cell {
		padding-left: 0;
		padding-right: 0;
	}

	::v-deep .el-input__inner {
		padding-left: 30px !important;
		border-radius: 0px !important;
	}

	::v-deep .el-dialog {
		width: 540px;
	}

	::v-deep .el-dialog__body {
		padding: 0 20px;
	}

	::v-deep .el-table {
		width: 490px;
		max-height: 350px;
		overflow-y: auto;
		margin: 13px 0;
	}

	::v-deep .el-dialog__headerbtn {
		font-size: 20px !important;
	}

	.w490 {
		width: 490px;
	}

	.dia-btn {
		width: 120px;
		line-height: 30px;
		height: 30px;
	}

	.el-button {
		padding: 0;
		font-size: 12px;
	}

	.el-table::before {
		z-index: inherit;
	}

	.confirm-btn {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}
	.demo-ruleForm {
		width: 100%;
		z-index: 1;
	}
	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}
</style>
