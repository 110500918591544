<template>
	<div class="select-area">
		<el-cascader :props="props" placeholder="请选择行政区域" popper-class="select-area-popper" @change="change" v-model="selectArea" ref="cascader" />
	</div>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from 'vuex'

	export default {
		name: "SelectArea",
		props: {
			value: Array
		},
		data() {
			return {

			}
		},
		computed: {
			props() {
				// console.log('this.value', this.value);
				return {
					lazy: true,
					lazyLoad: (node, resolve) => {
						// console.log('node', node);
						// console.log('resolve', resolve);
						let id = -1;
						if (!node.root) {
							id = node.value;
						}
						// console.log('node.value', node.value);
						this.checkLoadList(id).then((data) => {
							const result = Array.from(data).map(i => ({
								value: i.fGovernmenAreaID,
								label: i.fGovernmenArea,
								leaf: i.fIsDetail,
							}));
							// console.log('result', result);
							resolve(result);
						});
					}
				};
			},
			selectArea: {
				get: function() {
					return this.value;
				},
				set: function() { //因为我这里并不需要其他属性也跟着一起改变 所以就这样写了
					// console.log('1')
				},
			}
		},
		methods: {
			change(val) {
				// console.log("this.$refs[‘cascader’].getCheckedNodes()[0].pathLabels", this.$refs['cascader'].getCheckedNodes()[0].pathLabels);
				this.$emit("input", val,this.$refs['cascader'].getCheckedNodes()[0].pathLabels);
			},
			async checkLoadList(id) {
				var data = [];
				await this.ApiRequestPostNOMess('/api/mall/ebbase/governmen-area/get-list-by-parentid-onstatus', {
						fParentID: id
					})
					.then(result => {
							// console.log('result', result);
							data = result.obj.items;
						},
						rej => {})
				// console.log('data', data);
				return data;
			}
		},
		mounted() {
			this.checkLoadList(-1);
		}
	}
</script>

<style lang="scss" scoped>
	.el-cascader::v-deep {
		width: 400px;

		.el-input.is-focus .el-input__inner {
			border-color: #F2F2F2;
		}

		.el-input .el-input__inner:focus {
			border-color: #F2F2F2;
		}

		.el-input__inner {
			border-color: #F2F2F2;
			height: 29px;
			line-height: 29px;
		}

		.el-input__inner:hover {
			border-color: #F2F2F2;
		}
	}
</style>
